import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React from "react";
import { computed } from "../../../util/functions";
import { SortDirection } from "../../../util/sortDirection";
import "./faTableHeadCell.scss";

type FaTableHeadCellProps = SortableProps & {
    /**
     * @default "left"
     */
    alignX?: "left" | "center" | "right";

    className?: string;
    children?: React.ReactNode;
};

export function FaTableHeadCell(props: FaTableHeadCellProps) {
    const { alignX = "left", ...restProps } = props;

    const handleToggleSort = () => {
        if (!restProps.sortable) {
            return;
        }

        const nextDirection = computed((): SortDirection => {
            switch (restProps.sortDirection) {
                case SortDirection.ASC:
                    return SortDirection.DESC;
                case SortDirection.DESC:
                    return SortDirection.ASC;
                default:
                    return restProps.defaultSortDirection ?? SortDirection.ASC;
            }
        });

        restProps.onSortChange(nextDirection);
    };

    return (
        <div
            onClick={handleToggleSort}
            className={clsx(
                "fa-table-head-cell",
                {
                    "fa-table-head-cell--align-x-left": alignX === "left",
                    "fa-table-head-cell--align-x-center": alignX === "center",
                    "fa-table-head-cell--align-x-right": alignX === "right",
                },
                {
                    "fa-table-head-cell--sortable": restProps.sortable,
                },
                restProps.className
            )}>
            {props.children}

            {restProps.sortable && (
                <span className="fa-table-head-cell__sort">
                    {restProps.sortDirection !== undefined && (
                        <FontAwesomeIcon
                            icon={faCaretUp}
                            className={clsx("fa-table-head-cell__sort-icon", {
                                "fa-table-head-cell__sort-icon--asc": restProps.sortDirection === SortDirection.ASC,
                                "fa-table-head-cell__sort-icon--desc": restProps.sortDirection === SortDirection.DESC,
                            })}
                        />
                    )}
                </span>
            )}
        </div>
    );
}

type SortableProps =
    | {
        sortable: true;
        /**
         * @default SortDirection.ASC
         */
        defaultSortDirection?: SortDirection;
        sortDirection: SortDirection | undefined;
        onSortChange: (direction: SortDirection) => void;
    }
    | {
        sortable?: false;
    };

type GetSortablePropsAccessor<TSortBy> = (forColumn: TSortBy) => SortableProps;

interface CreateSortalePropsAccessorParams<TSortBy> {
    sortBy: TSortBy;
    sortDirection: SortDirection;
    onSortByChange: (sortBy: TSortBy) => void;
}
export function createSortablePropsAccessor<TSortBy>(
    params: CreateSortalePropsAccessorParams<TSortBy>
): GetSortablePropsAccessor<TSortBy> {
    return forColumn => {
        return {
            sortable: true,
            sortDirection: forColumn === params.sortBy ? params.sortDirection : undefined,
            onSortChange: () => {
                params.onSortByChange(forColumn);
            },
        };
    };
}
