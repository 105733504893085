import clsx from "clsx";
import React from "react";
import "./faTableHead.scss";

interface FaTableHeadProps extends React.ComponentPropsWithoutRef<"div"> {
    /**
     * @default false
     */
    hidden?: boolean;
}

export function FaTableHead(props: FaTableHeadProps) {
    const { hidden = false, ...restProps } = props;

    return (
        <div
            {...restProps}
            className={clsx(
                "fa-table-head",
                {
                    "fa-table-head--hidden": hidden,
                },
                restProps.className
            )}
        />
    );
}
