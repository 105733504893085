import clsx from "clsx";
import React from "react";
import "./faTableRoot.scss";

interface FaTableRootProps {
    /**
     * String for CSS grid-template-columns
     * @example "5rem 1fr 4rem"
     */
    columns: string;

    /**
     * @default "light"
     */
    variant?: "light" | "white";

    children: React.ReactNode;
    className?: string;
}

export function FaTableRoot(props: FaTableRootProps) {
    const { variant = "light", ...restProps } = props;

    return (
        <div
            className={clsx(
                "fa-table-root",
                {
                    "fa-table-root--variant-light": variant === "light",
                    "fa-table-root--variant-white": variant === "white",
                },
                restProps.className
            )}
            style={{ gridTemplateColumns: restProps.columns }}>
            {restProps.children}
        </div>
    );
}
