import { FaTableRoot } from "./FaTableRoot/FaTableRoot";
import { FaTableHead } from "./FaTableHead/FaTableHead";
import { FaTableHeadCell } from "./FaTableHeadCell/FaTableHeadCell";
import { FaTableBody } from "./FaTableBody/FaTableBody";
import { FaTableRow } from "./FaTableRow/FaTableRow";
import { FaTableActionRow } from "./FaTableActionRow/FaTableActionRow";
import { FaTableCell } from "./FaTableCell/FaTableCell";
import { FaTableFooter } from "./FaTableFooter/FaTableFooter";
import { FaTableFooterCell } from "./FaTableFooterCell/FaTableFooterCell";

export const Root = FaTableRoot;
export const Head = FaTableHead;
export const HeadCell = FaTableHeadCell;
export const Body = FaTableBody;
export const Row = FaTableRow;
export const ActionRow = FaTableActionRow;
export const Cell = FaTableCell;
export const Footer = FaTableFooter;
export const FooterCell = FaTableFooterCell;
