import clsx from "clsx";
import React from "react";
import { Slot } from "@radix-ui/react-slot";
import "./faTableRow.scss";

interface FaTableRowProps extends React.ComponentPropsWithoutRef<typeof Slot> {
    /**
     * @default false
     */
    asChild?: boolean;
}

export function FaTableRow(props: FaTableRowProps) {
    const { asChild = false, ...restProps } = props;

    const Comp = asChild ? Slot : "div";

    return <Comp {...restProps} className={clsx("fa-table-row", restProps.className)} />;
}
