import { OrderByDirection } from "firebase/firestore";

export enum SortDirection {
    ASC,
    DESC,
}

export function toggleSortDirection(dir: SortDirection): SortDirection {
    switch (dir) {
        case SortDirection.ASC:
            return SortDirection.DESC;
        case SortDirection.DESC:
            return SortDirection.ASC;
    }
}

export function toFirestoreSortDirection(dir: SortDirection): OrderByDirection {
    switch (dir) {
        case SortDirection.ASC:
            return "asc";
        case SortDirection.DESC:
            return "desc";
    }
}
