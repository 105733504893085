import clsx from "clsx";
import React from "react";
import "./faTableCell.scss";

interface FaTableCellProps extends React.ComponentPropsWithoutRef<"div"> {
    /**
     * @default "left"
     */
    alignX?: "left" | "center" | "right";
}

export function FaTableCell(props: FaTableCellProps) {
    const { alignX = "left", ...restProps } = props;

    return (
        <div
            {...restProps}
            className={clsx(
                "fa-table-cell",
                {
                    "fa-table-cell--align-x-left": alignX === "left",
                    "fa-table-cell--align-x-center": alignX === "center",
                    "fa-table-cell--align-x-right": alignX === "right",
                },
                restProps.className
            )}
        />
    );
}
